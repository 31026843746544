<template>
  <div class="wrap__contents account-monthly--inner">
    <div class="toolbar">
      <div class="toolbar__items earchbar">
        <search
          :translations="translations"
          :filter-by="filterBy"
          :date-by="dateBy"
          date-type="date"
          @refresh="search"
          @trigger="trigger"
          @clear="search"
        />
      </div>
    </div>

    <div
      class="wrap__inner"
      :class="{'data-loading': showDataLoading}"
    >
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :is-loading="isDataLoading"
        :is-empty="isEmpty"
      />

      <tbl
        v-else
        ref="tbl"
        :data-loading="isDataLoading"
      >
        <template slot="head">
          <tbl-td name="date">
            {{ translate(translations, 'date') }}
          </tbl-td>

          <tbl-td name="users">
            {{ translate(translations, 'users') }}
          </tbl-td>

          <tbl-td name="holding">
            {{ translate(translations, 'holding') }}
          </tbl-td>

          <tbl-td name="cash">
            {{ translate(translations, 'cash') }}
          </tbl-td>

          <tbl-td name="point">
            {{ translate(translations, 'point') }}
          </tbl-td>

          <tbl-td name="bet-win">
            {{ translate(translations, 'bet') }}/{{ translate(translations, 'win') }}
          </tbl-td>

          <tbl-td name="commission">
            {{ translate(translations, 'commission') }}
          </tbl-td>
        </template>

        <template slot="body">
          <tbl-row
            v-for="(item, index) in accountData"
            :key="index"
          >
            <tbl-td name="date">
              {{ item.created_at }}
            </tbl-td>

            <tbl-td name="users">
              <tbl>
                <template slot="head">
                  <tbl-td>
                    {{ translate(translations, 'title') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'in') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'out') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'net') }}
                  </tbl-td>
                </template>

                <template slot="body">
                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'total') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.users.total | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.users.total | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'new') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.users.new | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.users.new | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'ban') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.users.ban | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.users.ban | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'deleted') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.users.deleted | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.users.deleted | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>
                </template>
              </tbl>
            </tbl-td>

            <tbl-td name="holding">
              <tbl>
                <template slot="head">
                  <tbl-td>
                    {{ translate(translations, 'title') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'in') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'out') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'net') }}
                  </tbl-td>
                </template>

                <template slot="body">
                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'amount') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.holding.amount | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.holding.amount | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'point') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.holding.point | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.holding.point | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'cash back') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.holding.cashback | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.holding.cashback | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                  </tbl-row>
                </template>
              </tbl>
            </tbl-td>

            <tbl-td name="cash">
              <tbl>
                <template slot="head">
                  <tbl-td>
                    {{ translate(translations, 'title') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'in') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'out') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'net') }}
                  </tbl-td>
                </template>

                <template slot="body">
                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'cash') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.cash.cash.in | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.cash.cash.out | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.cash.cash.in - item.cash.cash.out | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'manual') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.cash.manual.in | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.cash.manual.out | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.cash.manual.in - item.cash.manual.out | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'conversion') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.cash.conversion | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.cash.conversion | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                  </tbl-row>
                </template>
              </tbl>
            </tbl-td>

            <tbl-td name="point">
              <tbl>
                <template slot="head">
                  <tbl-td>
                    {{ translate(translations, 'title') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'in') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'out') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'net') }}
                  </tbl-td>
                </template>

                <template slot="body">
                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'point manual') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.manual.in | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.manual.out | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.manual.in - item.total_point.manual.out | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'point auto') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.auto | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.auto | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'point event') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.event | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.event | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'point conversion') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.conversion | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.total_point.conversion | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>
                </template>
              </tbl>
            </tbl-td>

            <tbl-td name="bet-win">
              <tbl>
                <template slot="head">
                  <tbl-td>
                    {{ translate(translations, 'title') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'bet') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'win') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'net') }}
                  </tbl-td>
                </template>

                <template slot="body">
                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'sports') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.sports.bet | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.sports.win | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.sports.bet - item.bet_win.sports.win | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'mini') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.mini.bet | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.mini.win | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.mini.bet - item.bet_win.mini.win | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'live') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.live.bet | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.live.win | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.bet_win.live.bet - item.bet_win.live.win | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                  </tbl-row>
                </template>
              </tbl>
            </tbl-td>

            <tbl-td name="commission">
              <tbl>
                <template slot="head">
                  <tbl-td>
                    {{ translate(translations, 'title') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'in') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'out') }}
                  </tbl-td>

                  <tbl-td>
                    {{ translate(translations, 'net') }}
                  </tbl-td>
                </template>

                <template slot="body">
                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'agent') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.commission.agent | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.commission.agent | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td>
                      {{ translate(translations, 'referral') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.commission.referral | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ 0 | FormatAmount('whole') }}
                    </tbl-td>

                    <tbl-td>
                      {{ item.commission.referral | FormatAmount('whole') }}
                    </tbl-td>
                  </tbl-row>

                  <tbl-row>
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                  </tbl-row>

                  <tbl-row>
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                    <tbl-td />
                  </tbl-row>
                </template>
              </tbl>
            </tbl-td>
          </tbl-row>
        </template>

        <template slot="footer">
          <pagination
            v-model="params.page"
            :total="count"
            :limit="params.rows"
            :rows="params.rows"
            @page="trigger"
          />
        </template>
      </tbl>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

//= mixins
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

//= components
import Modal from '@/components/base/Modal'
import Search from '@/components/base/search/Search'
import Checkbox from '@/components/base/Checkbox'
import Pagination from '@/components/base/Pagination'
import CheckDataList from '@/components/base/CheckDataList'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

//= translation
import { translations } from '@/assets/js/translations/Account'

export default {
  name: 'AccountMonthly',

  components: {
    Modal,
    Checkbox,
    Search,
    Pagination,
    CheckDataList
  },

  filters: {
    FormatAmount
  },

  mixins: [
    getListStatus
  ],

  data () {
    const params = Object.assign(
      {
        rows: 10,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        date_by: '',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      translations,
      params,
      selected: {},
      filterBy: [],
      dateBy: [
        {
          value: 'created_at',
          display: 'created date'
        }
      ],

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,
      accountData: []
    }
  },

  computed: {
    ...mapState('accountMonthly', ['list', 'count'])
  },

  watch: {
    $route ($router) {
      if (!this.showDataLoading) {
        this.showDataLoading = true
      }
      this.search(this.generate($router.query))
    }
  },

  async created () {
    this.search()
  },

  methods: {
    ...mapActions('accountMonthly', ['get']),

    trigger (q) {
      this.params = q || this.params
      this.$router.push({ path: '/account/monthly', query: this.params })
    },

    async search (q) {
      this.params = q || this.params

      // == flags reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      await this.get({
        params: this.params
      })

      this.isDataLoading = false
      this.showDataLoading = false
      this.accountData = JSON.parse(JSON.stringify(this.list))

      if (!this.list.length) {
        this.isEmpty = true
      }
    }
  }
}
</script>
